.footer-container-404,
.footer-container {
  background-color: $blue;
  color: $white;
  padding: 100px 0 30px;
  font-family: $secondary-font;
  font-size: 16px;

  @media #{$monitor} {
    padding: 40px 0 20px;
  }

  h5 {
    font-family: $primary-font;
    font-size: 22px;
    color: $green;
    text-transform: uppercase;
    margin-top: 0;
  }

  .section {
    height: 100%;

    @media #{$monitor} {
      max-width: 294px;
    }
  }

  .footer-content {
    margin-bottom: 120px;

    @media #{$monitor} {
      margin-bottom: 30px;
    }

    .plexo-container {
      display: flex;
      flex-direction: column;

      .plexo-logo {
        width: 180px;
        margin-bottom: 30px;

        @media #{$monitor} {
          margin-bottom: 30px;
        }
      }

      .plexo-text {
        font-family: $primary-font;
        margin-bottom: 50px;
        line-height: 30px;
        flex-grow: 1;

        @media #{$monitor} {
          margin-bottom: 22px;
          font-size: 13px;
          line-height: 20px;
        }
      }

      .media-container {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        width: 160px;

        @media #{$monitor} {
          width: 260px;
        }

        img {
          width: 28px;

          @media #{$monitor} {
            width: 35px;
          }
        }
      }

      @media #{$monitor} {
        align-items: center;
        text-align: center;
      }
    }

    .contact-container {
      font-style: normal;

      .primary-button {
        background-color: transparent;
        width: fit-content;
        margin-top: auto;

        &:hover {
          background-color: $secondary-green;
          text-decoration: none;
        }
      }

      dl {
        margin-block-end: 0.75rem;
      }

      dt {
        opacity: 0.6;
        margin-block-end: 0.25rem;

        &:not(:first-child) {
          margin-block-start: 1rem;
        }
      }
    }

    .contact-container,
    .solutions-container {
      padding-left: 80px;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      a {
        color: $white;
        font-family: $secondary-font;
        font-size: 16px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      @media #{$monitor} {
        display: none;
      }
    }
  }

  .cedu-copyright-container {
    display: flex;
    align-items: center;

    @media #{$monitor} {
      flex-direction: column;
    }

    .copyright {
      font-family: $secondary-font;
      font-size: 16px;

      @media #{$monitor} {
        order: 2;
      }
    }

    .cedu {
      font-family: $primary-font;
      font-size: 13px;
      display: flex;
      align-items: center;
      margin-left: 30px;

      img {
        width: 90px;
        margin-left: 12px;
      }

      @media #{$monitor} {
        margin-bottom: 46px;
        font-size: 13px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media #{$monitor} {
      order: 1;
      margin-bottom: 30px;
    }
  }

  .legacy-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    //TODO: remove when T&C go into production
    justify-content: flex-end;

    @media #{$monitor} {
      padding-left: 0;
      font-size: 12px;
      justify-content: center;
    }

    .terms-conditions {
      flex-grow: 1;
    }

    a {
      text-decoration: none;
      color: $white;

      //TODO: remove when T&C go into production
      @media #{$monitor} {
        text-align: center;
        margin-right: 0;
      }
    }

    & :hover {
      text-decoration: underline;
    }
  }
}

.footer-container-404 {
  padding: 20px 0;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 4;

  .plexo-logo {
    width: 180px;
    margin-bottom: 45px;
    margin-top: 30px;
  }
}
