@import './variables';
@import './common';
@import './mui';
@import './faqs';
@import './ebook';
@import './adsForm';
@import './adsFooter';
@import '../components/common/Header/header';
@import '../components/common/Footer/footer.scss';
@import '../components/common/ListItems/listItems';
@import '../components/common/Solutions/solutions';
@import '../components/common/Benefits/benefits';
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;600;700&display=swap');

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding-right: 0 !important; // Overrides Mui's scroll lock padding
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(../assets/img/bg-plexo@2x.png);
  background-size: contain;
  background-repeat: repeat-y;
  background-position: 0;
  max-width: 100%;
  overflow-x: hidden;
}

.main {
  display: flex;
  flex-direction: column;
  background-color: $main-bg;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
  margin-top: 80px;
}
